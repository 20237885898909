@import "./breakpoints.scss";

.btn-row{
    width: 256px;
    position: relative;
    z-index: 2;
    right: -38%;
    bottom: -29%;
    a{
        display: inline-block;
        font-size: 1,6rem;
        color: rgb(243, 104, 104);
        text-decoration: none;
        display: flex;
        align-items: center;
        font-weight: 600;
      
        svg{
            margin-left: 16px;
            padding: 12px;
            height: 24px;
            width: 24px;
            border: 2px solid rgb(224, 108, 108);
            border-radius: 100px;
            transition: 0.4s ease-in-out;
           
        }
        &:hover {
            svg{
                background: black;
                color: rgb(196, 57, 57);
            }
        }
    }
}
.btn-row-cancel{

    top: 7%;
    right: -3%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
        top: 9%;
        right: 1%;
    }
    @media screen and (max-width: 992px) {
        top: 11%;
        right: 5%;
    }
    @media screen and (max-width: 768px) {
        top: 2%;
        right: -44%;
    }
    @media screen and (max-width: 576px) {
        top: 2%;
        right: -67%;
    }
   

    

}
.btn-row-Right{
    cursor: pointer;
    bottom: -39%;
   
    // @media screen and (max-width: 1200px) {
    //    display: none;
    // }


}
// .child-content{
//     @media screen and (max-width:1200px) {
//         display: contents !important;
//      }
//      @media screen and (max-width:992px) {
//         display: none !important;
//      }
//   }
  .img-child{
    @media screen and (max-width:1200px) {
        width: 25% !important;
     }
     @media screen and (max-width:992px) {
        top: 22%;
        left: 4%;
        height: 27rem;
     }
     @media screen and (max-width:768px) {
        top: 0%;
        left: 39%;
        height: 16%;  
     }
     @media screen and (max-width:576px) {
        top: 0%;
        left: 35%;
        height: 16%;  
     }
  }
  #img-child3{
    @media screen and (max-width:992px) {
        top: 4%;
        left: 23%;
     }
     @media screen and (max-width:768px) {
        top: 0%;
        left: 39%;
     }
     @media screen and (max-width:576px) {
        top: 0%;
        left: 35%;
        height: 16%;  
     }
  }
  #img-child4{
    @media screen and (max-width:992px) {
        top: 4%;
        left: 29%;
     }
     @media screen and (max-width:768px) {
        top: 0%;
        left: 39%;
     }
     @media screen and (max-width:576px) {
        top: 0%;
        left: 35%;
        height: 16%;  
     }
  }

  .bachdround-image{
      
    @media screen and (max-width:768px) {
        width: 136% !important;
     }
     @media screen and (max-width:576px) {
        width: 150% !important;
     }
  }
